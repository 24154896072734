import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  Grid,
  Text,
  VStack,
  useToast,
  useDisclosure,
  HStack,
  Heading,
  Divider,
} from '@chakra-ui/react';
import io from 'socket.io-client';
import axios from 'axios';
import AddItem from './AddItem';
import ItemCard from './ItemCard';
import ClearableInput from './ClearableInput';
import ItemModal from './ItemModal';

// Controls section component
const ListControls = ({ searchTerm, setSearchTerm, onItemAdded }) => {
  return (
    <VStack spacing={4} width="100%">
      <Heading
        size="2xl"
        color="white"
        mb={4}
        textAlign="center"
        fontWeight="bold"
      >
        SHOPPING LIST
      </Heading>

      {/* Search and Add Item Controls */}
      <HStack width="100%" spacing={4}>
        <Box flex="2">
          <ClearableInput
            placeholder="Search items..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            bg="gray.700"
            color="white"
            isSearch={true} 
            _placeholder={{ color: 'gray.400' }}
            borderRadius="md"
          />
        </Box>
        <Box flex="1">
          <AddItem onItemAdded={onItemAdded} />
        </Box>
      </HStack>
    </VStack>
  );
};

// Admin view component showing items grouped by store
const AdminStoreGroup = ({ storeName, items, onItemClick, onStatusChange, storeColor }) => {
  return (
    <Box mb={8}>
      <Box 
        py={2} 
        px={4} 
        mb={4}
        bg="gray.800"
        borderRadius="md"
        borderLeft="4px solid"
        borderLeftColor={storeColor || "gray.500"}
      >
        <Heading size="md" color="white">
          {storeName}
        </Heading>
      </Box>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)"
        }}
        gap={6}
      >
        {items.map(item => (
          <ItemCard
            key={item._id}
            item={item}
            onClick={() => onItemClick(item)}
            onStatusChange={onStatusChange}
            isAdmin={true}
          />
        ))}
      </Grid>
      <Divider mt={8} borderColor="gray.700" />
    </Box>
  );
};

// User view component showing items alphabetically
const UserItemList = ({ items, onItemClick, onStatusChange }) => {
  return (
    <Grid
      templateColumns={{
        base: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
        lg: "repeat(3, 1fr)"
      }}
      gap={6}
    >
      {items.map(item => (
        <ItemCard
          key={item._id}
          item={item}
          onClick={() => onItemClick(item)}
          onStatusChange={onStatusChange}
          isAdmin={false}
        />
      ))}
    </Grid>
  );
};

function ShoppingList() {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const isAdmin = localStorage.getItem('isAdmin') === 'true';
  const userId = localStorage.getItem('userId');

  const apiBaseUrl = process.env.NODE_ENV === 'production'
    ? 'https://list.gqfam.com/api'
    : 'http://localhost:7000/api';

  const fetchItems = useCallback(async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/items`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });
      
      // Show all items with status 'Need' for everyone
      const relevantItems = response.data.filter(item => item.status === 'Need');

      setItems(relevantItems);
    } catch (error) {
      console.error('Error fetching items:', error);
      toast({
        title: "Error fetching items",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [apiBaseUrl, toast]);

  // Filter and sort items based on search term and user role
  useEffect(() => {
    let filtered = items;
    
    if (searchTerm) {
      filtered = items.filter(item =>
        item.itemName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (isAdmin) {
      // For admin, group by store and sort alphabetically within each store
      filtered.sort((a, b) => {
        const storeCompare = a.store.storeName.localeCompare(b.store.storeName);
        if (storeCompare !== 0) return storeCompare;
        return a.itemName.localeCompare(b.itemName);
      });
    } else {
      // For users, just sort alphabetically
      filtered.sort((a, b) => a.itemName.localeCompare(b.itemName));
    }

    setFilteredItems(filtered);
  }, [items, searchTerm, isAdmin]);

  const handleStatusChange = async (itemId, newStatus) => {
    if (!isAdmin) {
      toast({
        title: "Permission denied",
        description: "Only admins can mark items as 'Have'",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    try {
      // For admin, change status for everyone
      await axios.put(
        `${apiBaseUrl}/items/${itemId}`,
        { status: newStatus },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );
      
      setItems(prevItems => prevItems.filter(item => item._id !== itemId));
      
      toast({
        title: `Item marked as ${newStatus}`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleItemUpdate = async (updatedItem) => {
    try {
      await axios.put(
        `${apiBaseUrl}/items/${updatedItem._id}`,
        updatedItem,
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      await fetchItems();

      toast({
        title: "Item updated successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleItemDelete = async (itemId) => {
    try {
      await axios.delete(`${apiBaseUrl}/items/${itemId}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') }
      });

      setItems(prevItems => prevItems.filter(item => item._id !== itemId));

      toast({
        title: "Item deleted successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error deleting item",
        description: error.response?.data?.msg || "An error occurred",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  // Set up Socket.IO listeners
  useEffect(() => {
    const socket = io(apiBaseUrl);

    socket.on('itemAdded', (newItem) => {
      if (newItem.status === 'Need') {
        setItems(prevItems => [...prevItems, newItem]);
      }
    });

    socket.on('itemUpdated', (updatedItem) => {
      setItems(prevItems => {
        if (updatedItem.status === 'Have') {
          return prevItems.filter(item => item._id !== updatedItem._id);
        }
        return prevItems.map(item =>
          item._id === updatedItem._id ? updatedItem : item
        );
      });
    });

    socket.on('itemDeleted', (itemId) => {
      setItems(prevItems => prevItems.filter(item => item._id !== itemId));
    });

    return () => socket.disconnect();
  }, [apiBaseUrl]);

  // Initial data fetch
  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  // Render the list
  const renderItems = () => {
    if (filteredItems.length === 0) {
      return (
        <Text color="gray.400" textAlign="center" py={8}>
          No items in your shopping list!
        </Text>
      );
    }

    if (isAdmin) {
      // Group items by store for admin view
      const groupedItems = filteredItems.reduce((acc, item) => {
        const storeName = item.store.storeName;
        if (!acc[storeName]) acc[storeName] = [];
        acc[storeName].push(item);
        return acc;
      }, {});

      return Object.entries(groupedItems)
        .sort(([storeA], [storeB]) => storeA.localeCompare(storeB))
        .map(([storeName, storeItems]) => (
          <AdminStoreGroup
            key={storeName}
            storeName={storeName}
            items={storeItems}
            onItemClick={(item) => {
              setSelectedItem(item);
              onOpen();
            }}
            onStatusChange={handleStatusChange}
            storeColor={storeItems[0]?.store?.color}
          />
        ));
    } else {
      // User view - simple alphabetical list
      return (
        <UserItemList
          items={filteredItems}
          onItemClick={(item) => {
            setSelectedItem(item);
            onOpen();
          }}
          onStatusChange={handleStatusChange}
        />
      );
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="stretch">
        <ListControls
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onItemAdded={fetchItems}
        />

        {renderItems()}

        {selectedItem && (
          <ItemModal
            isOpen={isOpen}
            onClose={() => {
              setSelectedItem(null);
              onClose();
            }}
            item={selectedItem}
            onUpdate={handleItemUpdate}
            onDelete={handleItemDelete}
          />
        )}
      </VStack>
    </Container>
  );
}

export default ShoppingList;
